import { Component } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";

@Component({
  selector: "app-unauthorized-component",
  templateUrl: "unauthorized.component.html",
  styleUrls: ["unauthorized.component.scss"],
})
export class UnauthorizedComponent {

  constructor(private bfcConfigurationService: BfcConfigurationService) {
  }

  public goBack() {
    window.location.href = this.bfcConfigurationService.configuration.opb2bDashboardUrl;
  }

}
