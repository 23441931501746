export const DE_TRANSLATIONS = {
  "SERVICE_NAME": "Netzinformation",
  "ERROR_MESSAGE": "Beim Laden der Daten ist ein Fehler aufgetreten. Es konnten nicht alle Informationen geladen werden.",
  "TAB": {
    "DASHBOARD_LABEL": "Übersicht",
    "MAP_LABEL": "Karte",
    "LIST_LABEL": "Liste",
  },
  "OVERVIEW": {
    "LAST_UPDATE": "Zuletzt aktualisiert:",
    "FAQ_HINT": "FAQ Netzinformation",
  },
  "DASHBOARD": {
    "TILE_TITLES": {
      "UNDERGROUND_LINES": "KLE",
      "OVERHEAD_LINES": "FLE",
      "SUBSTATIONS_BY_AGE": "TS nach Alter",
      "CABINETS_BY_AGE": "VK nach Alter",
      "SUBSTATIONS": "TS",
      "CABINETS": "VK",
      "ENERGY_CONSUMERS": "HAS",
      "STRUCTURES": "TRW",
      "SELF_GENERATION_PLANTS": "EEA",
      "STREETLIGHTS": "OEB",
    },
  },
  "MAP": {
    "LEGEND": "Legende",
  },
  "MAP_PAGE": {
    "FILTER": "Netzelemente filtern*",
    "FILTER_ANNOTATION": "*Die Zahl beschreibt das Total der Anlagen.",
    "OPEN": "Öffnen",
    "MINIMIZE": "Minimieren",
  },
  "MAP_ELEMENTS": {
    "SUBSTATIONS": "Trafostationen ({{total}})",
    "CABINETS": "Verteilkabinen ({{total}})",
    "STREETLIGHTS": "Beleuchtungen ({{total}})",
    "SELF_GENERATION_PLANTS": "Eigenerzeugungsanlagen ({{total}})",
  },
  "LIST": {
    "SEARCH": "Suche",
    "COLUMNS": {
      "NAME": "Name",
      "TYPE": "Art",
      "NUMBER": "Nummer",
      "LOCATION": "Ort",
      "INSTALLATION_YEAR": "Baujahr",
    },
  },
  "POWER_LINES_LENGTH_INFO_TILE": {
    "TOTAL_LENGTH": "Gesamtlänge",
    "16KV": "16 kV",
    "400V": "400 V",
    "230V": "230 V",
  },
  "COLUMN_CHART": {
    "0_TO_9": "0-9",
    "10_TO_19": "10-19",
    "20_TO_29": "20-29",
    "30_TO_39": "30-39",
    "40_TO_49": "40-49",
    "50_PLUS": "50+",
  },
  "ORGANIZATION_COMPONENT": {
    "SELECT_ORGANIZATION": "Organisation auswählen",
  },
  "UNAUTHORIZED": {
    "TITLE": "Kein Zugriff",
    "DESCRIPTION": "Der Service steht Ihnen leider nicht zur Verfügung.",
  },
  "UNITS": {
    "KM": "km",
    "PERCENT": "%",
  },
  "TYPES": {
    "SUBSTATION": "Trafostation",
    "CABINET": "Verteilkabine",
    "ENERGYCONSUMER": "Hausanschluss",
    "STRUCTURE": "Tragwerk",
    "SELFGENERATIONPLANT": "Eigenerzeugungsanlage",
    "STREETLIGHT": "Beleuchtung",
  },
  "BACK_BUTTON": "zurück zum Kundencenter",
};
