import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BfcTranslationModule } from "@bfl/components/translation";
import { UnauthorizedComponent } from "./unauthorized.component";
import { BfcSinglePageLayoutModule } from "@bfl/components/single-page-layout";

@NgModule({
  imports: [
    BfcTranslationModule,
    FlexLayoutModule,
    CommonModule,
    BfcSinglePageLayoutModule,
  ],
  declarations: [
    UnauthorizedComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
})
export class UnauthorizedModule {
}