import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Inject,
  Injectable,
  ViewContainerRef,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { PowerResourcesEntry } from "../../overview/model/power-resources-entry.interface";
import InfoWindow = google.maps.InfoWindow;
import { InfoWindowComponent } from "../../info-window/info-window.component";

@Injectable()
export class InfoWindowService {
  private infoWindow: InfoWindow;

  private componentRef: ComponentRef<InfoWindowComponent>;

  private viewContainerRef: ViewContainerRef;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    @Inject(DOCUMENT) private document) {
  }

  public createInfoWindow(): InfoWindow {
    const infoWindow: InfoWindow = new google.maps.InfoWindow({
      disableAutoPan: true,
      maxWidth: 300,
    });
    infoWindow.setContent("<span id='infoWindow'></span>");
    this.infoWindow = infoWindow;
    return infoWindow;
  }

  public openInfoWindow(resourceEntry: PowerResourcesEntry, map: google.maps.Map, marker: google.maps.Marker): void {
    // Add InfoWindow to the DOM by opening without position property or Marker
    this.infoWindow.open(map);
    // wait for the element to be in the DOM before creating the component
    setTimeout(() => {
      this.createInfoWindowComponent(resourceEntry);
      this.infoWindow.open(null, marker);
    }, 0);
  }

  public closeInfoWindow(): void {
    if (this.infoWindow) {
      this.infoWindow.close();
    }
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  public setRootViewContainerRef(viewContainerRef) {
    this.viewContainerRef = viewContainerRef;
  }

  private createInfoWindowComponent(resourceEntry: PowerResourcesEntry): ComponentRef<InfoWindowComponent> {
    // 0. Create a component factory
    const factory = this.componentFactoryResolver.resolveComponentFactory(InfoWindowComponent);

    // 1. Create a component reference from the component (calls the Component constructor) and add to the list
    const componentRef = factory.create(this.viewContainerRef.parentInjector);
    componentRef.instance.resourceEntry = resourceEntry;
    this.componentRef = componentRef;

    // 2. Attach component to the appRef so that it's inside the ng component tree
    this.applicationRef.attachView(componentRef.hostView);

    // 3. Get DOM element from component
    const componentElement = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    // 4. Append DOM element to document DOM
    const element = document.getElementById("infoWindow");
    if (element) {
      element.appendChild(componentElement);
    } else {
      // eslint-disable-next-line no-console
      console.error("InfoWindow not found in DOM");
    }

    return componentRef;
  }

}
