import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST,
  BfcAuthenticationInterceptor,
  BfcAuthenticationModule,
} from "@bfl/components/authentication";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { BFC_TRANSLATIONS, BfcTranslationModule, BfcTranslationService } from "@bfl/components/translation";
import { translations } from "./config/translations/translations";
import { BFC_CONFIGURATIONS, BfcConfigurationModule } from "@bfl/components/configuration";
import { BfcGoogleAnalyticsModule } from "@bfl/components/google-analytics";
import { configuration } from "./config/configuration";
import { AppRoutingModule } from "./app-routing.module";
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { authenticationInterceptorWhitelist } from "./config/authentication-interceptor-whitelist";
import { OverlayModule } from "@angular/cdk/overlay";
import { InfoWindowComponent } from "./info-window/info-window.component";
import { BfcNotificationModule } from "@bfl/components/notification";
import { UnauthorizedModule } from "./core/unauthorized/unauthorized.module";
import { initializeGoogleMaps } from "./core/initialization/initializer-googlemaps";
import { PowersystemresourcesErrorHandler } from "./core/error/powersystemresources-error-handler";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    AppRoutingModule,
    BfcTranslationModule,
    BfcGoogleAnalyticsModule,
    BfcConfigurationModule,
    BfcAuthenticationModule,
    BfcNotificationModule,
    CoreModule,
    SharedModule,
    OverlayModule,
    UnauthorizedModule,
  ],
  declarations: [
    AppComponent,
    InfoWindowComponent,
  ],
  entryComponents: [
    InfoWindowComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BfcAuthenticationInterceptor,
      multi: true,
    },
    {
      provide: BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST,
      useValue: authenticationInterceptorWhitelist,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeGoogleMaps,
      multi: true,
    },
    {
      provide: BFC_TRANSLATIONS,
      useValue: translations,
      multi: true,
    },
    {
      provide: BFC_CONFIGURATIONS,
      useValue: configuration,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: PowersystemresourcesErrorHandler,
    },
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule {

  constructor(private bfcTranslationService: BfcTranslationService) {
    AppModule.loadGoogleMapScript(bfcTranslationService.language);
  }

  private static loadGoogleMapScript(language: string) {
    // var key = "AIzaSyDX_WN1MAOJ6wWEqJWfuG0cxts54wiqoGI"; // old key
    const key = "AIzaSyDyvSvXvSmKZeRYnG6jjrsvnxI1WYUIR_c";    // INFO this key is taken from lightning-outage...
    const scriptSrc = "https://maps.google.com/maps/api/js?language=" + language + "&key=" + key + "&callback=googleMapsCallback";
    const scriptTag = document.createElement("script");
    scriptTag.setAttribute("src", scriptSrc);
    scriptTag.setAttribute("async", "");
    document.head.appendChild(scriptTag);
  }
}
