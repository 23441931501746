import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { Observable } from "rxjs";
import { PowerLinesLengthInfo } from "../../overview/model/power-lines-length-info.interface";
import { PowerResourcesWithDataAggregation } from "../../overview/model/power-resources-with-data-aggregation.interface";
import { PowerResourcesInfo } from "../../overview/model/power-resources-info.interface";
import { shareReplay, switchMap } from "rxjs/operators";
import { OrganisationService } from "../organisation/organisation.service";
import { Organisation } from "../../generated/op-self-service/model/organisation";

@Injectable()
export class ResourcesService {

  private restApiUrl = this.bfcConfigurationService.configuration.restApiUrl;

  private undergroundLinesLengthInfoCache$: Observable<PowerLinesLengthInfo>;

  private overheadLinesLengthInfoCache$: Observable<PowerLinesLengthInfo>;

  private substationsInfoWithDataAggregationCache$: Observable<PowerResourcesWithDataAggregation>;

  private cabinetsInfoWithDataAggregationCache$: Observable<PowerResourcesWithDataAggregation>;

  private energyConsumersInfoCache$: Observable<PowerResourcesInfo>;

  private structuresInfoCache$: Observable<PowerResourcesInfo>;

  private selfGenerationPlantsInfoCache$: Observable<PowerResourcesInfo>;

  private streetlightsInfoCache$: Observable<PowerResourcesInfo>;

  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService,
    private organisationService: OrganisationService) {
  }

  getUndergroundLinesLengthInfo(shouldUpdate: boolean = false): Observable<PowerLinesLengthInfo> {
    if (!this.undergroundLinesLengthInfoCache$ || shouldUpdate) {
      this.undergroundLinesLengthInfoCache$ = this.requestUndergroundLinesLengthInfo().pipe(
        shareReplay({ bufferSize: 1, refCount: true }),
      );
    }
    return this.undergroundLinesLengthInfoCache$;
  }

  getOverheadLinesLengthInfo(shouldUpdate: boolean = false): Observable<PowerLinesLengthInfo> {
    if (!this.overheadLinesLengthInfoCache$ || shouldUpdate) {
      this.overheadLinesLengthInfoCache$ = this.requestOverheadLinesLengthInfo().pipe(
        shareReplay({ bufferSize: 1, refCount: true }),
      );
    }
    return this.overheadLinesLengthInfoCache$;
  }

  getSubstationsInfoWithDataAggregation(shouldUpdate: boolean = false): Observable<PowerResourcesWithDataAggregation> {
    if (!this.substationsInfoWithDataAggregationCache$ || shouldUpdate) {
      this.substationsInfoWithDataAggregationCache$ = this.requestSubstationsInfoWithDataAggregation().pipe(
        shareReplay({ bufferSize: 1, refCount: true }),
      );
    }
    return this.substationsInfoWithDataAggregationCache$;
  }

  getCabinetsInfoWithDataAggregation(shouldUpdate: boolean = false): Observable<PowerResourcesWithDataAggregation> {
    if (!this.cabinetsInfoWithDataAggregationCache$ || shouldUpdate) {
      this.cabinetsInfoWithDataAggregationCache$ = this.requestCabinetsInfoWithDataAggregation().pipe(
        shareReplay({ bufferSize: 1, refCount: true }),
      );
    }
    return this.cabinetsInfoWithDataAggregationCache$;
  }

  getEnergyConsumersInfo(shouldUpdate: boolean = false): Observable<PowerResourcesInfo> {
    if (!this.energyConsumersInfoCache$ || shouldUpdate) {
      this.energyConsumersInfoCache$ = this.requestEnergyConsumersInfo().pipe(
        shareReplay({ bufferSize: 1, refCount: true }),
      );
    }
    return this.energyConsumersInfoCache$;
  }

  getStructuresInfo(shouldUpdate: boolean = false): Observable<PowerResourcesInfo> {
    if (!this.structuresInfoCache$ || shouldUpdate) {
      this.structuresInfoCache$ = this.requestStructuresInfo().pipe(
        shareReplay({ bufferSize: 1, refCount: true }),
      );
    }
    return this.structuresInfoCache$;
  }

  getSelfGenerationPlantsInfo(shouldUpdate: boolean = false): Observable<PowerResourcesInfo> {
    if (!this.selfGenerationPlantsInfoCache$ || shouldUpdate) {
      this.selfGenerationPlantsInfoCache$ = this.requestSelfGenerationPlantsInfo().pipe(
        shareReplay({ bufferSize: 1, refCount: true }),
      );
    }
    return this.selfGenerationPlantsInfoCache$;
  }

  getStreetlightsInfo(shouldUpdate: boolean = false): Observable<PowerResourcesInfo> {
    if (!this.streetlightsInfoCache$ || shouldUpdate) {
      this.streetlightsInfoCache$ = this.requestStreetlightsInfo().pipe(
        shareReplay({ bufferSize: 1, refCount: true }),
      );
    }
    return this.streetlightsInfoCache$;
  }

  private requestUndergroundLinesLengthInfo(): Observable<PowerLinesLengthInfo> {
    return this.organisationService.getCurrentOrganisation().pipe(
      switchMap((organisation: Organisation) => {
        return this.httpClient.get<PowerLinesLengthInfo>(
          `${this.restApiUrl}/${organisation?.foreignKeys?.eicx}/undergroundLines`);
      }),
    );
  }

  private requestOverheadLinesLengthInfo(): Observable<PowerLinesLengthInfo> {
    return this.organisationService.getCurrentOrganisation().pipe(
      switchMap((organisation: Organisation) => {
        return this.httpClient.get<PowerLinesLengthInfo>(
          `${this.restApiUrl}/${organisation?.foreignKeys?.eicx}/overheadLines`);
      }),
    );
  }

  private requestSubstationsInfoWithDataAggregation(): Observable<PowerResourcesWithDataAggregation> {
    return this.organisationService.getCurrentOrganisation().pipe(
      switchMap((organisation: Organisation) => {
        return this.httpClient.get<PowerResourcesWithDataAggregation>(
          `${this.restApiUrl}/${organisation?.foreignKeys?.eicx}/substations`);
      }),
    );
  }

  private requestCabinetsInfoWithDataAggregation(): Observable<PowerResourcesWithDataAggregation> {
    return this.organisationService.getCurrentOrganisation().pipe(
      switchMap((organisation: Organisation) => {
        return this.httpClient.get<PowerResourcesWithDataAggregation>(
          `${this.restApiUrl}/${organisation?.foreignKeys?.eicx}/cabinets`);
      }),
    );
  }

  private requestEnergyConsumersInfo(): Observable<PowerResourcesInfo> {
    return this.organisationService.getCurrentOrganisation().pipe(
      switchMap((organisation: Organisation) => {
        return this.httpClient.get<PowerResourcesInfo>(
          `${this.restApiUrl}/${organisation?.foreignKeys?.eicx}/energyConsumers`);
      }),
    );
  }

  private requestStructuresInfo(): Observable<PowerResourcesInfo> {
    return this.organisationService.getCurrentOrganisation().pipe(
      switchMap((organisation: Organisation) => {
        return this.httpClient.get<PowerResourcesInfo>(
          `${this.restApiUrl}/${organisation?.foreignKeys?.eicx}/structures`);
      }),
    );
  }

  private requestSelfGenerationPlantsInfo(): Observable<PowerResourcesInfo> {
    return this.organisationService.getCurrentOrganisation().pipe(
      switchMap((organisation: Organisation) => {
        return this.httpClient.get<PowerResourcesInfo>(
          `${this.restApiUrl}/${organisation?.foreignKeys?.eicx}/selfGenerationPlants`);
      }),
    );
  }

  private requestStreetlightsInfo(): Observable<PowerResourcesInfo> {
    return this.organisationService.getCurrentOrganisation().pipe(
      switchMap((organisation: Organisation) => {
        return this.httpClient.get<PowerResourcesInfo>(
          `${this.restApiUrl}/${organisation.foreignKeys?.eicx}/streetLights`);
      }),
    );
  }
}
