import { Component, OnInit } from "@angular/core";
import localeCH from "@angular/common/locales/de-CH";
import { registerLocaleData } from "@angular/common";

@Component({
  selector: "app-component",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {

  ngOnInit(): void {
    registerLocaleData(localeCH, "ch");
  }
}
