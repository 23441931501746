import { catchError, map, shareReplay } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { CoreModule } from "./core.module";
import { Organisation } from "../generated/op-self-service/model/organisation";
import { Service } from "../generated/op-self-service/model/service";

@Injectable({
  providedIn: CoreModule,
})
export class SelfserviceCacheService {

  private organisationsCache: Observable<Organisation[]>;

  private organisationServicesCache: { [key: number]: Observable<Service[]> } = {};

  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService) {
  }

  public getOrganisations(): Observable<Organisation[]> {
    if (!this.organisationsCache) {
      this.organisationsCache = this.requestOrganisations().pipe(
        catchError(() => of([])), // also catching failed requests
        shareReplay({ bufferSize: 1, refCount: true }),
      );
    }
    return this.organisationsCache;
  }

  public getOrganisationServices(organisationId: number): Observable<Service[]> {
    if (!this.organisationServicesCache[organisationId]) {
      this.organisationServicesCache[organisationId] = this.requestOrganisationServices(organisationId).pipe(
        catchError(() => of([])), // also catching failed requests
        shareReplay({ bufferSize: 1, refCount: true }),
      );
    }
    return this.organisationServicesCache[organisationId];
  }

  private requestOrganisationServices(organisationId: number): Observable<Service[]> {
    return this.httpClient.get<any>(this.bfcConfigurationService.configuration.opSelfServiceApiUrl + "/me/organisations/" + organisationId + "/services").pipe(
      map((resources: any): Service[] =>
        resources?._embedded?.services ? resources._embedded.services : [],
      ),
    );
  }

  private requestOrganisations(): Observable<Organisation[]> {
    return this.httpClient.get<any>(this.bfcConfigurationService.configuration.opSelfServiceApiUrl + "/me/organisations").pipe(
      map((resources: any): Organisation[] => {
        return resources._embedded ? resources._embedded.organisations : [];
      }),
    );
  }

}