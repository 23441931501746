import { Component, HostBinding } from "@angular/core";
import { PowerResourcesEntry } from "../overview/model/power-resources-entry.interface";
import { BfcTranslationService } from "@bfl/components/translation";

@Component({
  selector: "app-info-window",
  templateUrl: "./info-window.component.html",
  styleUrls: ["info-window.component.scss"],
})
export class InfoWindowComponent {
  public resourceEntry: PowerResourcesEntry;

  @HostBinding("class")
  classes = "info-window-component";

  constructor(private translationService: BfcTranslationService){
  }

  public getTranslatedResourceType(resourceEntry: PowerResourcesEntry): string {
    const entryType = resourceEntry.type;
    let resultType = "-";
    if (entryType && entryType.length > 0) {
      resultType = this.translationService.translate(`TYPES.${entryType.toUpperCase()}`);
    }
    return resultType;
  }
}
