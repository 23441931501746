import { Injectable } from "@angular/core";
import { BfcGoogleAnalyticsService } from "@bfl/components/google-analytics";
import { BfcTranslationService } from "@bfl/components/translation";
import { BfcConfigurationService } from "@bfl/components/configuration";

@Injectable()
export class InitializerProvider {

  constructor(private bfcTranslateService: BfcTranslationService,
    private bfcGoogleAnalyticsService: BfcGoogleAnalyticsService,
    private bfcConfigurationService: BfcConfigurationService) {
  }

  public initialize() {
    this.bfcGoogleAnalyticsService.initGoogleTagManager(
      this.bfcConfigurationService.configuration.googleTagManagerContainerId,
    );
    const globalNavElements = document.getElementsByTagName("bfe-global-navigation");
    if (globalNavElements.length === 1) {
      globalNavElements[0].setAttribute(
        "service-name",
        JSON.stringify(this.bfcTranslateService.translate("SERVICE_NAME")),
      );
    } else {
      throw new Error("too many or no global navigation element found");
    }

    const footerElements = document.getElementsByTagName("bfe-footer");
    if (footerElements.length === 1) {
      footerElements[0].setAttribute("customer-segment", "B2B");
    } else {
      throw new Error("too many or no bfe-footer element found");
    }
  }
}
