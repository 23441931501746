import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { map, switchMap } from "rxjs/operators";
import { SelfserviceCacheService } from "../selfservice-cache.service";
import { Organisation } from "../../generated/op-self-service/model/organisation";
import { Service } from "../../generated/op-self-service/model/service";
import { forkJoin, Observable, ReplaySubject } from "rxjs";

@Injectable()
export class OrganisationService {

  // Subscribe for continuos organisation updates
  public selectedOrganisation: ReplaySubject<Organisation> = new ReplaySubject(1);

  private authorizedOrganisations: Organisation[];

  constructor(private configurationService: BfcConfigurationService,
    private selfserviceCacheService: SelfserviceCacheService) {
    // cache authorized organisations and select default one
    this.getAuthorizedOrganisations().subscribe(organisations => {
      if (organisations.length > 0) {
        this.authorizedOrganisations = organisations;
        this.selectedOrganisation.next(organisations[0]);
      }
    });
  }

  // call this to get an organisation observable that completes
  public getCurrentOrganisation(): Observable<Organisation> {
    return new Observable<Organisation>(sub => {
      this.selectedOrganisation.subscribe(organisation => {
        sub.next(organisation);
        sub.complete();
      });
    });
  }

  // get all organisations with the app role
  public getAuthorizedOrganisations(): Observable<Organisation[]> {
    if (this.authorizedOrganisations?.length) {
      // return loaded authorized organizations
      return new Observable<Organisation[]>((obs) => {
        obs.next(this.authorizedOrganisations);
        obs.complete();
      });
    } else {
      // load organisations
      return this.selfserviceCacheService.getOrganisations().pipe(
        switchMap((organisations: Organisation[]) => {
          return forkJoin(
            organisations.map(org => this.selfserviceCacheService.getOrganisationServices(org.commonOrganisationId)),
          ).pipe(
            map((services: Service[][]) =>
            // removing organisations where the user is missing the app role
              organisations.filter((organisation, i) =>
                services[i].some(
                  (service: Service) => service.code === this.configurationService.configuration.userRole),
              ),
            ),
          );
        }),
      );
    }
  }

}