<bfc-single-page-layout>
    <a class="bfc-margin-bottom-2" (click)="goBack()">
        <span class="b2b-back-button">«</span>{{ "BACK_BUTTON" | bfcTranslate}}
    </a>
    <div class="full-width" fxLayout="column" fxLayoutAlign="center center">
        <bfe-icon name="bfc-icon-key" class="unauthorized-key"></bfe-icon>
        <h1>{{ "UNAUTHORIZED.TITLE" | bfcTranslate }}</h1>
        <p>{{ "UNAUTHORIZED.DESCRIPTION" | bfcTranslate }}</p>
    </div>
    <a class="bfc-margin-bottom-2 bfc-margin-top-2" (click)="goBack()">
        <span class="b2b-back-button">«</span>{{ "BACK_BUTTON" | bfcTranslate}}
    </a>
</bfc-single-page-layout>
