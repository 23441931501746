import { Injectable } from "@angular/core";
import { BfcNotificationService, BfcNotificationType } from "@bfl/components/notification";
import { BfcTranslationService } from "@bfl/components/translation";
import { of } from "rxjs";

@Injectable()
export class PowersystemresourcesErrorHandler {

  constructor(private notificationService: BfcNotificationService,
    private translateService: BfcTranslationService) {
  }

  handleError(error: unknown) {
    this.notificationService.clearNotifications();
    this.notificationService.showNotification({
      type: BfcNotificationType.ERROR,
      message: this.translateService.translate("ERROR_MESSAGE"),
      options: {
        duration: 0,
      },
    });
    // Don't swallow the error
    // eslint-disable-next-line no-console
    console.error(error);
    return of(undefined);
  }
}
