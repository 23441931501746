import { RouterModule, Routes } from "@angular/router";
import { UnauthorizedComponent } from "./core/unauthorized/unauthorized.component";
import { NgModule } from "@angular/core";

const appRoutes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "overview",
  },
  {
    path: "overview",
    loadChildren: () => import("./overview/overview.module").then((m) => m.OverviewModule),
  },
  {
    path: "unauthorized",
    component: UnauthorizedComponent,
  },
  {
    path: "**",
    redirectTo: "overview",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}